<template>
<div class="create-role-wrapper">
    <v-text-field
            class="evi-text-field"
            color="#44D370"
            v-model="role.name"
            :disabled="!canEdit"
    >
        <template v-slot:label>
            Название роли
            <span class="important">*</span>
        </template>
    </v-text-field>
    <div class="role__color mt-5">
        <span>Цвет роли</span>
        <div class="role__picker mt-2">
            <v-color-picker
                dot-size="25"
                hide-canvas
                show-swatches
                hide-inputs
                swatches-max-height="200"
                class="mt-5"
                v-model="role.color"
                :disabled="!canEdit"
            />
        </div>
        <div class="role__buttons d-flex align-center" v-if="canEdit">
            <v-btn class="evi-button-green mr-7" @click="$emit('reset')">
                Сброс
            </v-btn>
            <v-btn class="evi-button-green evi-button-green--fill" :disabled="!role.name" @click="save">
                Сохранить
            </v-btn>
        </div>
    </div>
</div>
</template>

<script>
import {hasRight} from '@/utils/access/hasRight';

export default {
    name: "RoleForm",
    props: {
        selectedRole: {
            type: Object,
            required: true,
        },
        isAdmin: {
            type: Boolean,
            default: false,
        },
        isUser: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        role: {
        }
    }),
    computed: {
        canEdit () {
            return !this.isUser && !this.isAdmin && this.hasRight('permissions.manage');
        }
    },
    methods: {
        hasRight: hasRight,
        reset () {
            this.role = this.selectedRole;
        },
        save () {
            let payload = {
                name: this.role.name,
                color: this.role.color,
                id: this.role.id,
                permissions: [],
            };
            this.role.permissions.forEach(i => {
                payload.permissions.push(i.name);
            });

            this.$emit('saveRole', payload);
        }
    },
    watch: {
        selectedRole(val) {
          this.role = val;
        }
    },
    mounted() {
        this.role = this.selectedRole;
    }
}
</script>

<style lang="scss" scoped>
.create-role-wrapper {
    max-width: 350px;
}
.role {
    &__color {
        span {
            font-weight: normal;
            font-size: 14px;
            color: #464A53;
        }
    }
    &__picker {
        background-color: #FAFAFA;
        width: 100%;
        height: 320px;
        display: flex;
        justify-content: center;
    }
    &__buttons {
        margin-top: 50px;
    }
}
</style>
