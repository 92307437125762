<template>
  <div>
    <div class="role__right" v-for="el in sortedRights" :key="el.name">
      <div class="right-headline mb-5">{{ el.title }}</div>
      <v-checkbox
          class="evi-rights-checkbox"
          color="#44D370"
          v-for="permission in el.items"
          :key="permission.name"
          v-model="permission.checked"
          :disabled="!canEdit"
      >
        <template v-slot:label>
          {{ permission.pretty_name }}
        </template>
      </v-checkbox>
    </div>

    <div class="role__buttons d-flex align-center" v-if="canEdit">
      <v-btn class="evi-button-green mr-7" @click="$emit('reset')">
        Сброс
      </v-btn>
      <v-btn class="evi-button-green evi-button-green--fill" @click="save">
        Сохранить
      </v-btn>
    </div>
  </div>
</template>

<script>
import {hasRight} from '@/utils/access/hasRight';

export default {
  name: "RightsForm",
  props: {
    selectedRole: {
      type: Object,
      required: true,
    },
    rights: {
      type: Array,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isUser: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    canEdit() {
      return !this.isUser && !this.isAdmin && this.hasRight('permissions.manage');
    }
  },
  data: () => ({
    sortedRights: [
      {
        name: 'tasks',
        title: 'Задачи',
        items: [],
      },
      {
        name: 'analytics',
        title: 'Аналитика',
        items: [],
      },
      {
        name: 'clients',
        title: 'Клиенты',
        items: [],
      },
      {
        name: 'products',
        title: 'Продукты',
        items: [],
      },
      {
        name: 'deals',
        title: 'Сделки',
        items: [],
      },
      {
        name: 'bills',
        title: 'Счета',
        items: [],
      },
      {
        name: 'chat',
        title: 'Чат',
        items: [],
      },
      {
        name: 'mail',
        title: 'Почта',
        items: [],
      },
      {
        name: 'mailers',
        title: 'Рассылки',
        items: [],
      },
      {
        name: 'users',
        title: 'Сотрудники',
        items: [],
      },
      {
        name: 'subdivisions',
        title: 'Подразделения',
        items: [],
      },
      {
        name: 'activitylog',
        title: 'Журнал событий',
        items: [],
      },
      {
        name: 'integrations',
        title: 'Интеграции',
        items: [],
      },
      {
        name: 'scripts',
        title: 'Быстрые фразы',
        items: [],
      },
      {
        name: 'automations',
        title: 'Автоматизация',
        items: [],
      },
      {
        name: 'permissions',
        title: 'Роли и права',
        items: [],
      },
      {
        name: 'dealStatuses',
        title: 'Статусы сделок',
        items: [],
      },
      {
        name: 'clientStatuses',
        title: 'Статусы клиентов',
        items: [],
      },
      {
        name: 'tags',
        title: 'Теги',
        items: [],
      },
      {
        name: 'payment',
        title: 'Тарифы и оплата',
        items: [],
      },
    ],
    localRights: [],
  }),
  methods: {
    hasRight: hasRight,
    rightsSearch(name) {
      this.localRights.forEach(item => {
        if (item.name.includes(`${name}.`)) {
          //Проходимся по массиву и забираем соответствующие права в соотвествующий по названию массив
          this.sortedRights.find(el => el.name === name).items.push(item);
        }
      })
    },
    rightsAdd() {
      this.localRights = this.rights;

      this.checkRights();

      for (const r of this.sortedRights) {
        this.rightsSearch(r.name);
      }
    },
    checkRights() {
      this.localRights.forEach(item => {
        item.checked = !!this.selectedRole.permissions.find(i => i.name === item.name);
      })
    },
    clearArrays() {
      this.sortedRights.forEach(item => item.items = []);
    },
    save() {
      let payload = {
        name: this.selectedRole.name,
        color: this.selectedRole.color,
        id: this.selectedRole.id,
        permissions: [],
      };

      this.sortedRights.forEach(r => {
        r.items.forEach(i => {
          if (i.checked) {
            payload.permissions.push(i.name);
          }
        })
      });

      this.$emit('saveRole', payload);
    }
  },
  watch: {
    selectedRole() {
      this.clearArrays();
      this.rightsAdd();
    }
  },
  mounted() {
    this.rightsAdd();
  }
}
</script>

<style lang="scss" scoped>
.role {
  &__right {
    background: #FFFFFF;
    box-shadow: 0 8px 14px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    padding: 30px;
    width: 100%;
    margin-bottom: 15px;

    .right-headline {
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  &__buttons {
    margin-top: 50px;
    max-width: 350px;
  }
}
</style>
