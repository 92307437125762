<template>
<v-row class="roles">
    <v-col cols="3" v-if="loading">
        <div class="roles__headline">
            <v-skeleton-loader
                type="heading"
                width="100%"
                height="100%"
                class="ml-4"
            />
        </div>
        <div class="roles__items">
            <v-skeleton-loader
                type="list-item"
                width="100%"
                height="100%"
                v-for="i in 7"
                :key="i"
            />
        </div>
    </v-col>
    <v-col cols="3" v-if="!loading">
        <div class="roles__headline">
            <h3>Роли</h3>
            <v-btn text icon @click="createNewRole" v-if="hasRight('permissions.manage')">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </div>
        <v-text-field
                label="Поиск"
                class="evi-text-field mt-5 mr-3"
                color="#44D370"
                v-model="search"
                clearable
        >
            <template v-slot:append>
                <img class="mt-1" width="15" :src="require('@/assets/img/analytics/search.svg')" alt="">
            </template>
        </v-text-field>
        <div class="roles__items">
            <div class="roles__item" @click="selectRole(item)" v-for="item in filteredRoles" :key="item.id">
                <span class="color" :style="{backgroundColor: `${item.color}`}"/>
                <span class="title-name">
                    {{item.name}}
                    <span class="title-line" :class="{'active' : item.id === selectedRole.id}" />
                </span>
            </div>
        </div>
    </v-col>
    <v-divider vertical></v-divider>
    <v-col class="ml-5" v-if="loading">
        <div class="roles__headline mb-2">
            <v-skeleton-loader
                type="heading"
                width="100%"
                height="100%"
            />
        </div>
        <div class="roles__fields">
            <v-skeleton-loader
                type="heading"
                width="100%"
                height="100%"
                class="mb-7"
            />
            <v-skeleton-loader
                type="heading"
                width="100%"
                height="100%"
                class="mb-7"
            />
            <v-skeleton-loader
                type="card, actions"
                width="350"
                height="100%"
            />
        </div>
    </v-col>
    <v-col class="ml-5" v-if="!loading">
        <div class="roles__headline mb-2">
            <h4 class="mt-1">РОЛЬ — {{selectedRole.name}}</h4>
            <v-btn class="evi-delete-button" @click="deleteRoleModal = true" v-if="!isAdmin && !isUser && hasRight('permissions.manage')" color="#ffffff" height="59">
                Удалить роль
                <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
        </div>
        <v-tabs
                v-model="tab"
                align-with-title
                class="roles__tabs"
        >
            <v-tabs-slider color="#44D370"></v-tabs-slider>
            <v-tab
                    v-for="item in items"
                    :key="item"
            >
                {{ item }}
            </v-tab>
        </v-tabs>
        <div class="roles__fields">
            <RoleForm :selectedRole="selectedRole" :isAdmin="isAdmin" :isUser="isUser" @reset="reset" v-if="tab === 0" @saveRole="saveRole"/>
            <RightsForm :selectedRole="selectedRole" :rights="rights" :isAdmin="isAdmin" :isUser="isUser" @reset="reset" @saveRole="saveRole" v-if="tab === 1"/>
        </div>
        <v-dialog
                v-model="deleteRoleModal"
                max-width="600"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Удаление роли
                </v-card-title>
                <v-card-text>Вы действительно хотите удалить роль <span class="font-weight-bold">"{{selectedRole.name}}"</span>?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            text
                            @click="deleteRoleModal = false"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            color="red"
                            text
                            @click="deleteRoleItem"
                    >
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-col>
</v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RoleForm from "../../components/permissions/RoleForm";
import RightsForm from "../../components/permissions/RightsForm";
import {hasRight} from '@/utils/access/hasRight';

export default {
    name: "RolesAndRights",
    components: {RightsForm, RoleForm},
    data: () => ({
        localRoles: '',
        search: "",
        tab: 0,
        items: [
            'Роль', 'Права доступа',
        ],
        selectedRole: {},
        deleteRoleModal: false,
    }),
    computed: {
        ...mapState("permissions", ["roles", "rights"]),
        ...mapState("common", ["loading"]),
        isAdmin () {
            return this.selectedRole.id === 1;
        },
        isUser () {
            return this.selectedRole.id === 2;
        },
        filteredRoles () {
            if(!this.search){
                return this.localRoles;
            }
            return this.localRoles.filter(i => {
                return i.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
            })
        },
    },
    methods: {
        ...mapActions({
            getRoles: 'permissions/getRoles',
            createRole: 'permissions/createRole',
            deleteRole: 'permissions/deleteRole',
            getSelectedRole: 'permissions/getSelectedRole',
            saveSelectedRole: 'permissions/saveSelectedRole',
            getPermissions: 'permissions/getPermissions',
        }),
        hasRight: hasRight,
        updateRoles () {
            this.localRoles = this.roles;
        },
        selectRole (item) {
            this.getSelectedRole(item.id).then((response) => {
                this.selectedRole = response;
            })
        },
        reset () {
            this.selectRole(this.selectedRole);
        },
        saveRole(item) {
            this.saveSelectedRole(item).then((res) => {
                this.selectedRole = res;
            });
        },
        rolesInit () {
            this.getRoles().then(() => {
                this.updateRoles();
                if (!this.selectedRole.name) {
                    this.selectRole(this.localRoles[0]);
                }
            });
        },
        createNewRole () {
            this.createRole().then(() => {
                this.rolesInit();
            })
        },
        deleteRoleItem () {
            this.deleteRole(this.selectedRole).then(() => {
                this.selectedRole = {};
                this.rolesInit();
            });
            this.deleteRoleModal = false;
        }
    },
    mounted() {
        this.rolesInit();
        this.getPermissions();
    },

}
</script>

<style lang="scss" scoped>
.roles {
    padding: 60px 25px;
    background: #FFFFFF;
    //box-shadow: 10px -2px 54px rgba(0, 0, 0, 0.06);
    border-radius: 10px;

    &__headline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3 {
            font-weight: bold;
            font-size: 20px;
            color: $evi-headline-color;
            text-transform: uppercase;
        }
        i {
            color: $evi-headline-color !important;
        }
        h4 {
            font-weight: 600;
            font-size: 14px;
            color: $evi-headline-color !important;
            text-transform: uppercase;
            margin-bottom: 15px;
        }
    }
    &__items {
        margin-top: 49px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
    }
    &__item {
        color: $evi-headline-color;
        display: flex;
        cursor: pointer;
        margin-bottom: 15px;
        position: relative;
        margin-left: 21px;

        .title-name {
            display: inline;
            font-weight: 600;
            font-size: 14px;
            position: relative;
        }

        .title-line {
            display: block;
            height: 1px;
            width: 100%;
            position: absolute;
            background: $evi-headline-color;
            opacity: 0;
            transition: 0.2s;
            left: 0;
            bottom: -4px;

            &.active {
                opacity: 1;
            }
        }

        &:hover {
            .title-line {
                opacity: 1;
            }
        }

        .color {
            display: block;
            content: '';
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background-color: #99AAB5;
            position: absolute;
            left: -21px;
            top: 2px;
        }
    }
    &__tabs {
        .v-tab {
            color: $evi-headline-color;
            font-weight: 600;
            font-size: 14px;
            text-transform: none;
            margin-left: 0 !important;
            letter-spacing: 0 !important;
            padding: 0;
            margin-right: 20px;
            min-width: auto;
        }
    }
    &__fields {
        margin-top: 50px;
    }

    .evi-delete-button {
        width: 226px;
    }
}
</style>
